import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Snackbar, Alert, CircularProgress, Switch
} from '@mui/material';
import { Visibility, VisibilityOff, Edit, Delete } from '@mui/icons-material';
import api from '../../../../services/api';

function APIKeyManager() {
  const [apiKeys, setApiKeys] = useState([]);
  const [newApiKey, setNewApiKey] = useState({ name: '', description: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [showKey, setShowKey] = useState({});
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/smsko/api-keys/');
      setApiKeys(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Erreur lors de la récupération des clés API', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateApiKey = async () => {
    setLoading(true);
    try {
      await api.post('/api/smsko/generate-api-key/', newApiKey);
      await fetchApiKeys(); // Refresh the list after generating a new API key
      setNewApiKey({ name: '', description: '' });
      setSnackbar({ open: true, message: 'Clé API générée avec succès', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Erreur lors de la génération de la clé API', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleToggleApiKey = async (apiKeyId) => {
    try {
      await api.post(`/api/smsko/toggle-api-key/${apiKeyId}/`);
      await fetchApiKeys(); // Refresh the list after toggling API key status
    } catch (error) {
      setSnackbar({ open: true, message: 'Erreur lors de la modification du statut de la clé API', severity: 'error' });
    }
  };

  const handleUpdateApiKey = async () => {
    setLoading(true);
    try {
      await api.put(`/api/smsko/update-api-key/${selectedApiKey.id}/`, selectedApiKey);
      await fetchApiKeys(); // Refresh the list after updating an API key
      setOpenDialog(false);
      setSnackbar({ open: true, message: 'Clé API mise à jour avec succès', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Erreur lors de la mise à jour de la clé API', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteApiKey = async (apiKeyId) => {
    setLoading(true);
    try {
      await api.delete(`/api/smsko/delete-api-key/${apiKeyId}/`);
      await fetchApiKeys(); // Refresh the list after deleting an API key
      setSnackbar({ open: true, message: 'Clé API supprimée avec succès', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Erreur lors de la suppression de la clé API', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleShowKey = (apiKeyId) => {
    setShowKey(prevShowKey => ({ ...prevShowKey, [apiKeyId]: !prevShowKey[apiKeyId] }));
  };

  const handleOpenDialog = (apiKey) => {
    setSelectedApiKey(apiKey);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedApiKey(null);
    setOpenDialog(false);
  };

  const handleShowDetails = (apiKey) => {
    setSelectedApiKey(apiKey);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setSelectedApiKey(null);
    setShowDetails(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 1, p: 3 }}>
        <Typography variant="h4" color="primary">Gérer les clés API - SMS by BEFIANA</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <TextField
            label="Nom"
            value={newApiKey.name}
            onChange={(e) => setNewApiKey({ ...newApiKey, name: e.target.value })}
            sx={{ mr: 2 }}
          />
          <TextField
            label="Description"
            value={newApiKey.description}
            onChange={(e) => setNewApiKey({ ...newApiKey, description: e.target.value })}
            sx={{ mr: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleGenerateApiKey} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Générer Clé API'}
          </Button>
        </Box>
      </Paper>

      <Paper elevation={1} sx={{ margin: 'auto', mt: 1, mb: 1, p: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>API Key</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Compteur</TableCell>
                <TableCell>Dernière utilisation</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiKeys.map((apiKey) => (
                <TableRow key={apiKey.id}>
                  <TableCell>{apiKey.name}</TableCell>
                  <TableCell>{apiKey.description}</TableCell>
                  <TableCell>
                    <Tooltip title={showKey[apiKey.id] ? apiKey.api_key : 'Cliquez pour voir'}>
                      <IconButton onClick={() => handleShowKey(apiKey.id)}>
                        {showKey[apiKey.id] ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={apiKey.active}
                      onChange={() => handleToggleApiKey(apiKey.id)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{apiKey.count}</TableCell>
                  <TableCell>{apiKey.date_last_used ? new Date(apiKey.date_last_used).toLocaleString() : 'N/A'}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenDialog(apiKey)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDeleteApiKey(apiKey.id)}>
                      <Delete />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleShowDetails(apiKey)}>
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Modifier Clé API</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            fullWidth
            value={selectedApiKey?.name || ''}
            onChange={(e) => setSelectedApiKey({ ...selectedApiKey, name: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            fullWidth
            value={selectedApiKey?.description || ''}
            onChange={(e) => setSelectedApiKey({ ...selectedApiKey, description: e.target.value })}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Annuler</Button>
          <Button onClick={handleUpdateApiKey} color="primary">Sauvegarder</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showDetails} onClose={handleCloseDetails} fullWidth maxWidth="sm">
        <DialogTitle>Détails de l'API Key</DialogTitle>
        <DialogContent>
          <Typography variant="body1"><b>ID :</b> {selectedApiKey?.api_id}</Typography>
          <Typography variant="body1"><b>API Key :</b> {selectedApiKey?.api_key}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">Fermer</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default APIKeyManager;