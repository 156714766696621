import React, { useState } from 'react';
import { TextField, Button, Snackbar, Backdrop, CircularProgress, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import api from '../../../../services/api';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ContactForm() {
  const [contact, setContact] = useState({
    phone_number: '',
    first_name: '',
    birthday: null,
    office_email: '',
    home_email: '',
    company: '',
    position: '',
    address: ''
  });
  const [showOptions, setShowOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContact(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (newValue) => {
    setContact(prev => ({ ...prev, birthday: newValue }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Nettoyer le numéro de téléphone et préparer les données
    const cleanNumber = contact.phone_number.replace(/^0+/, '').replace(/\s/g, '');
    const dataToSend = {
      phone_number: cleanNumber,
      first_name: contact.first_name || null,
      birthday: contact.birthday ? contact.birthday.format('YYYY-MM-DD') : null,
      office_email: contact.office_email || null,
      home_email: contact.home_email || null,
      company: contact.company || null,
      position: contact.position || null,
      address: contact.address || null
    };

    try {
      await api.post('/api/smsko/add-contact/', dataToSend);
      setOpenSnackbar(true);
      setContact({
        phone_number: '',
        first_name: '',
        birthday: null,
        office_email: '',
        home_email: '',
        company: '',
        position: '',
        address: ''
      });
    } catch (error) {
      console.error("Erreur lors de l'enregistrement du contact:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
      <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
        <TextField
          label="Numéro de téléphone (+261)"
          variant="outlined"
          name="phone_number"
          value={contact.phone_number}
          onChange={handleChange}
          inputProps={{ maxLength: 10 }} // +261 et 10 chiffres
          required
          fullWidth
          style={{ marginBottom: '20px' }}
        />
        <TextField
          label="Nom"
          variant="outlined"
          name="first_name"
          value={contact.first_name}
          onChange={handleChange}
          fullWidth
          style={{ marginBottom: '20px' }}
        />
        {showOptions && (
          <>
            <DatePicker
              label="Date de naissance"
              value={contact.birthday}
              onChange={handleDateChange}
              format="DD/MM/YYYY"
              helperText="Cette information ce sera utile comme souhaiter anniversaire automatiquement vos clients"
              renderInput={(params) => <TextField {...params} />}
            />
            <Typography sx={{ fontSize: "11px", m: 1, color: "#757575" }} component="p" gutterBottom>
              Cette information est essentielle si vous souhaitez automatiser l'envoi de messages d'anniversaire à vos contacts au sein de votre entreprise ou organisation.
            </Typography>
            <TextField
              label="Email professionnel"
              variant="outlined"
              name="office_email"
              value={contact.office_email}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="Email personnel"
              variant="outlined"
              name="home_email"
              value={contact.home_email}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="Société"
              variant="outlined"
              name="company"
              value={contact.company}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="Poste"
              variant="outlined"
              name="position"
              value={contact.position}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: '20px' }}
            />
            <TextField
              label="Adresse"
              variant="outlined"
              name="address"
              value={contact.address}
              onChange={handleChange}
              fullWidth
              style={{ marginBottom: '20px' }}
            />
          </>
        )}
        <Button onClick={() => setShowOptions(!showOptions)} color="primary">
          {showOptions ? 'Afficher moins' : 'Afficher plus d\'options'}
        </Button>
        <Button type="submit" variant="contained" color="primary" style={{ display: 'block', marginTop: '20px' }}>
          Ajouter Contact
        </Button>
        <Backdrop open={loading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
          <Alert onClose={() => setOpenSnackbar(false)} severity="success">
            L'enregistrement ou la mise à jour des informations de votre numéro a été effectuée avec succès !
          </Alert>
        </Snackbar>
      </form>
    </LocalizationProvider>
  );
}

export default ContactForm;