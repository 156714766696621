import React from 'react';
import { Box, Toolbar, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import IBAppBarComponent from './components/AppBar';
import IBDrawerBarMD from './drawer/Drawer';
import IBDrawerTabsXS from './drawer/DrawerXS';
import VoucherDifference from './components/VoucherDifference';
import { Helmet } from 'react-helmet-async';

function IBStatistic() {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Helmet>
                <title>iBondacha</title>
            </Helmet>
            <IBAppBarComponent />
            <IBDrawerBarMD />

            <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                <Toolbar />
                <Typography variant="h3" color="initial" align='center'>iBondacha</Typography>
                <Divider>DASHBOARD</Divider>
                <br />

                <VoucherDifference />

            </Box>

            <IBDrawerTabsXS />
        </Box>
    );
}

export default IBStatistic;