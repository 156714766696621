import React from 'react';
import { Box, Typography } from '@mui/material';

const PostmanRunButton = () => {
  React.useEffect(() => {
    (function (p, o, s, t, m, a, n) {
      !p[s] &&
        (p[s] = function () {
          (p[t] || (p[t] = [])).push(arguments);
        });
      !o.getElementById(s + t) &&
        o.getElementsByTagName('head')[0].appendChild(
          ((n = o.createElement('script')),
          (n.id = s + t),
          (n.async = 1),
          (n.src = m),
          n)
        );
    })(window, document, '_pm', 'PostmanRunObject', 'https://run.pstmn.io/button.js');
  }, []);

  return (
    <Box sx={{ width: '100%', margin: 'auto', overflow: 'hidden', padding: '20px' }}>
        <Typography variant="h5" gutterBottom>
          Utilisation de l'API SMS by BEFIANA
        </Typography>
        <Typography variant="body1" paragraph>
          Vous pouvez utiliser Postman pour tester notre API d'envoi de SMS en masse. Cliquez sur le bouton ci-dessous pour
          forker la collection Postman et commencer à utiliser l'API immédiatement.
        </Typography>
        <Box>
          <div
            className="postman-run-button"
            data-postman-action="collection/fork"
            data-postman-visibility="public"
            data-postman-var-1="33028274-040bdbaa-dbb3-4782-a1ae-1d627009226a"
            data-postman-collection-url="entityId=33028274-040bdbaa-dbb3-4782-a1ae-1d627009226a&entityType=collection&workspaceId=aa88fde5-99d6-43c1-bb88-054cdcf0fd87"
          ></div>
        </Box>
        <Typography variant="body1" paragraph>
          Vous pouvez également accéder directement à la collection Postman via ce lien :{' '}
          <a
            href="https://www.postman.com/befiana/workspace/befiana-connect/collection/33028274-040bdbaa-dbb3-4782-a1ae-1d627009226a?action=share&creator=33028274"
            target="_blank"
            rel="noopener noreferrer"
          >
            Collection Postman BEFIANA Connect
          </a>
        </Typography>
        <Typography variant="body1">
          Utilisez cette collection pour explorer et tester les différentes fonctionnalités offertes par l'API SMS by
          BEFIANA.
        </Typography>
    </Box>
  );
};

export default PostmanRunButton;