import React, { useState } from 'react';
import {
    TextField, Button, Box, CircularProgress, Backdrop, Snackbar, Alert, Paper, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import api from '../../../../services/api';

const CreateVoucherCompta = () => {
    const [formData, setFormData] = useState({ voucher_group: 6, name_or_function: '', phone_number: '', amount: '' });
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [password, setPassword] = useState('');
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone_number' && value.length > 9) {
            return;
        }
        setFormData({ ...formData, [name]: value });
        validateForm({ ...formData, [name]: value });
    };

    const validateForm = (data) => {
        const { name_or_function, phone_number, amount } = data;
        const isFormValid = name_or_function && /^[1-9]\d{0,8}$/.test(phone_number) && amount > 0;
        setIsValid(isFormValid);
    };

    const sendSMS = async (phoneNumber) => {
        await api.post('/api/ibondacha/sms/', { phone_number: phoneNumber });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await api.post('/api/ibondacha/vouchers/', formData);
            setSnackbarMessage('Bon d\'achat créé avec succès. Un SMS a été envoyé au propriétaire.');
            setSnackbarSeverity('success');
            setFormData({ voucher_group: 6, name_or_function: '', phone_number: '', amount: '' });
            sendSMS(formData.phone_number);
            setIsValid(false);
            setPassword('');
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Erreur lors de la création du bon d\'achat.';
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
            console.error('Error creating voucher:', error);
        }
        setSnackbarOpen(true);
        setLoading(false);
    };

    const handlePasswordSubmit = async () => {
        setLoading(true);
        try {
            await api.post('/api/validate-password/', { password });
            setPasswordDialogOpen(false);
            handleSubmit();
        } catch (error) {
            setSnackbarMessage('Mot de passe invalide');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
        setLoading(false);
    };

    const openPasswordDialog = () => {
        setPasswordDialogOpen(true);
    };

    const closePasswordDialog = () => {
        setPasswordDialogOpen(false);
    };

    return (
        <Box sx={{ p: 3, mx: 'auto' }}>
            <Paper elevation={1} sx={{ p: 3 }}>
                <Backdrop open={loading} style={{ zIndex: 999 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Button variant="text" color="ib1" startIcon={<AddCircleOutlineIcon />}>
                    Créer un Bon d'Achat
                </Button>

                <TextField
                    fullWidth
                    margin="normal"
                    name="name_or_function"
                    label="Nom ou Fonction"
                    value={formData.name_or_function}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="phone_number"
                    label="Numéro de Téléphone"
                    value={formData.phone_number}
                    onChange={handleChange}
                    helperText="9 caractères maximum, sans zéro au début"
                    inputProps={{ maxLength: 9 }}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    name="amount"
                    label="Montant"
                    type="number"
                    value={formData.amount}
                    onChange={handleChange}
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="ib2"
                    onClick={openPasswordDialog}
                    disabled={!isValid}
                    sx={{ mt: 2 }}
                >
                    Créer
                </Button>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                    <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

                <Dialog open={passwordDialogOpen} onClose={closePasswordDialog} sx={{zIndex: 10}}>
                    <DialogTitle>Vérification du mot de passe</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="password"
                            label="Mot de passe"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePasswordDialog} color="secondary">
                            Annuler
                        </Button>
                        <Button onClick={handlePasswordSubmit} color="primary">
                            Valider
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Box>
    );
};

export default CreateVoucherCompta;