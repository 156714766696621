import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Snackbar, Typography, Alert, Box, LinearProgress } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import api from '../../../../services/api';

const ImportVouchers = ({ voucherGroupId, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [uploadData, setUploadData] = useState(null);

    useEffect(() => {
        if (loading) {
            const timer = setInterval(() => {
                setBuffer((prev) => {
                    const diff = Math.random() * 2;
                    return Math.min(prev + diff, 79);
                });
            }, 500);
            return () => clearInterval(timer);
        }
    }, [loading]);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        if (!file.name.endsWith('.xlsx')) {
            setSnackbarMessage('Veuillez uniquement télécharger un fichier au format .xlsx pour cette action.');
            setSnackbarOpen(true);
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('voucher_group', voucherGroupId);

        const config = {
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 85) / progressEvent.total);
                setProgress(percentCompleted);
                setBuffer(percentCompleted + 10);
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        api.post('/api/ibondacha/vouchers/import/', formData, config).then(response => {
            setUploadData(response.data);
            setSnackbarMessage('Vos bons d\'achat ont été importés avec succès.');
            setProgress(85);
            setBuffer(85);
            setSnackbarOpen(true);
            onSuccess();  // Appel de la fonction onSuccess après une importation réussie
        }).catch(error => {
            const errorMessage = error.response?.data?.errors?.join(', ') || error.response?.data?.error || 'Échec de l\'importation des bons d\'achat, veuillez réessayer.';
            setSnackbarMessage(errorMessage);
            setSnackbarOpen(true);
            console.error('Error:', error);
        }).finally(() => {
            setLoading(false);
            setTimeout(() => {
                setProgress(0);
                setBuffer(0);
            }, 3000);
        });
    }, [voucherGroupId, onSuccess]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Box {...getRootProps()} style={{ border: '2px dashed gray', padding: 20, textAlign: 'center' }}>
            <input {...getInputProps()} />
            <Typography variant="body1" color="initial" component="div">
                Glissez-déposez un fichier Excel ici, ou cliquez pour sélectionner le fichier de vos bons d'achat.
            </Typography>
            <Button variant="contained" startIcon={<CloudUploadIcon />} component="span">
                Charger le fichier
            </Button>

            {loading && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                    <Typography variant="subtitle2" color="ib2">
                        Traitement ou mise à jour en cours...
                    </Typography>
                </Box>
            )}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={uploadData ? "success" : "error"} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {uploadData && (
                <Typography variant="body1" style={{ marginTop: 20 }} component="div">
                    Nombre total de bons d'achat importés : {uploadData.vouchers ? uploadData.vouchers.length : 0}
                </Typography>
            )}
        </Box>
    );
};

export default ImportVouchers;