import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import api from '../../../../services/api';  

import AssignmentIcon from '@mui/icons-material/Assignment';
import { Helmet } from 'react-helmet-async';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ActivationRequestForm() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [formData, setFormData] = useState({
        company_name: '',
        representative_name: '',
        head_office: '',
        nif: '',
        stat: '',
        rcs: '',
        phone_number: '',
        email: '',
        activities: '',
        usage_description: ''
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await api.post('/api/smsko/activation/request/', formData);
            setSnackbarMessage('Demande de soumission réussie.');
            setSeverity('success');
            setOpenSnackbar(true);
            handleClose();
        } catch (error) {
            setSnackbarMessage('Échec de la soumission de la demande.');
            setSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Demander une activation SMS
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <Button variant="text" color="primary" startIcon={<AssignmentIcon />}>
                            Demande d'activation SMS
                        </Button>
                    <Helmet>
                        <title>Demande d'activation SMS - BEFIANA Connect</title>
                    </Helmet>
                
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                            Veuillez remplir le formulaire ci-dessous pour demander une activation de votre service SMS.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="company_name"
                        label="Nom de l'entreprise / organisation"
                        type="text"
                        fullWidth
                        value={formData.company_name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="representative_name"
                        label="Nom complet représentant"
                        type="text"
                        fullWidth
                        value={formData.representative_name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="head_office"
                        label="Siège Social"
                        type="text"
                        fullWidth
                        value={formData.head_office}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="nif"
                        label="NIF"
                        type="text"
                        fullWidth
                        value={formData.nif}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="stat"
                        label="STAT"
                        type="text"
                        fullWidth
                        value={formData.stat}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="rcs"
                        label="RCS"
                        type="text"
                        fullWidth
                        value={formData.rcs}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="phone_number"
                        label="Numéro Téléphone"
                        type="text"
                        fullWidth
                        value={formData.phone_number}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="activities"
                        label="Activités (Description activités)"
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        value={formData.activities}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="usage_description"
                        label="Explique brièvement votre utilisation SMS by BEFIANA"
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        value={formData.usage_description}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleSubmit} color="secondary" variant='contained'>
                        Soumettre
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity={severity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default ActivationRequestForm;