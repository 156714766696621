import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, List, ListItem, ListItemText, Divider, Snackbar, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder'; 
import api from '../../../../services/api';
import ContactsInList from './ContactsInList'; 

function ContactGroups({ onGroupChange }) {
    const [groups, setGroups] = useState([]);
    const [newGroupName, setNewGroupName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [selectedListId, setSelectedListId] = useState(null);

    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = async () => {
        const response = await api.get('/api/smsko/contacts/lists/');
        setGroups(response.data);
    };

    const handleAddGroup = async () => {
        if (newGroupName) {
            const response = await api.post('/api/smsko/contacts/lists/add/', { name: newGroupName });
            if (response.status === 201) {
                setGroups([...groups, response.data]);
                setNewGroupName('');
                setSnackbarMessage('Groupe ajouté avec succès');
                setSnackbarOpen(true);
                onGroupChange();
            }
        }
    };

    const handleDeleteGroup = async (id, event) => {
        event.stopPropagation();  
        const response = await api.delete(`/api/smsko/contacts/lists/delete/${id}/`);
        if (response.status === 204) {
            setGroups(groups.filter(group => group.id !== id));
            setSnackbarMessage('Groupe supprimé avec succès');
            setSnackbarOpen(true);
            onGroupChange();
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleListItemClick = (listId) => {
        setSelectedListId(listId);
    };

    const handleCloseDialog = () => {
        setSelectedListId(null);
    };

    return (
        <Box sx={{ p: 2 }}>
            <TextField
                label="Nouveau nom de groupe"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleAddGroup} disabled={!newGroupName}>
                Créer une liste
            </Button>
            <List sx={{ mt: 2, bgcolor: 'background.paper' }}>
                {groups.map(group => (
                    <React.Fragment key={group.id}>
                        <ListItem
                            button
                            onClick={() => handleListItemClick(group.id)}
                            secondaryAction={
                                <IconButton edge="end" onClick={(event) => handleDeleteGroup(group.id, event)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <FolderIcon color="primary" sx={{ mr: 1 }} /> 
                            <ListItemText primary={group.name} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
            {selectedListId && (
                <ContactsInList listId={selectedListId} handleClose={handleCloseDialog} />
            )}
        </Box>
    );
}

export default ContactGroups;