import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import SmsIcon from '@mui/icons-material/Sms';
import ContactPhone from '@mui/icons-material/ContactPhone';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import CampaignIcon from '@mui/icons-material/Campaign';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';


const NavHomeBC = { "color": "#d69300", "name": "Accueil", "icon": <HomeIcon />, "to": "/App/SmsKo/home" };
const NavSetting = { "color": "#000", "name": "Paramètre BEFIANA", "icon": <SettingsIcon />, "to": "/setting" };
const NavSettingSMS = { "color": "#d69300", "name": "Paramètre SMS", "icon": <AppSettingsAltIcon />, "to": "/App/SmsKo/setting" };
const NavDeveloper = { "color": "#d69300", "name": "Intégration App", "icon": <DeveloperModeIcon />, "to": "/App/SmsKo/developer" };
const NavSendSMS = { "color": "#69b0ee", "name": "Envoyer un SMS", "icon": <SmsIcon />, "to": "/App/SmsKo/send" };
const NavCampaignSMS = { "color": "#69b0ee", "name": "Campagne SMS", "icon": <CampaignIcon />, "to": "/App/SmsKo/campaign" };
const NavSMSall = { "color": "#69b0ee", "name": "SMS Envoyés", "icon": <SendAndArchiveIcon />, "to": "/App/SmsKo/sent" };
const NavContacts = { "color": "#d69300", "name": "Mes Contacts", "icon": <ContactPhone />, "to": "/App/SmsKo/contacts" };
const NavAddContact = { "color": "#d69300", "name": "Ajouter un Contact", "icon": <AddCircleOutlineIcon />, "to": "/App/SmsKo/add-contact" };
const NavBuySMS = { "color": "#69b0ee", "name": "Acheter des SMS", "icon": <ShoppingCartIcon />, "to": "/App/SmsKo/shop" };
const NavListContact = { "color": "#d69300", "name": "Mes Abonnés", "icon": <PlaylistAddCheckCircleIcon />, "to": "/App/Smsko/contact-groups" };
const NavImportContacts = { "color": "#d69300", "name": "Importer des Contacts", "icon": <CloudUploadIcon />, "to": "/App/SmsKo/import-contacts" };
const NavLogOut = { "color": "#000", "name": "Sortir SMS", "icon": <LogoutIcon />, "to": "/" };

const SMSnavDrawer = [NavHomeBC, NavSendSMS, NavCampaignSMS, NavSMSall, NavContacts, NavAddContact, NavImportContacts, NavListContact, NavBuySMS, NavSettingSMS, NavDeveloper, NavSetting, NavLogOut];

export default SMSnavDrawer;