import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Box, Button, Select, MenuItem, InputLabel, FormControl, CircularProgress, Snackbar, Alert } from '@mui/material';
import api from '../../../../services/api';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import 'dayjs/locale/fr'; 
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);
dayjs.locale('fr');

const isGSMCharacter = (char) => {
    const GSM_03_38_REGEX = /^[A-Za-z0-9 \r\n@£$¥èéêùìòôÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\[~\]|€]*$/;
    return GSM_03_38_REGEX.test(char);
};

const getMessageLength = (message) => {
    return message.split('').reduce((count, char) => count + (char === 'ê' || char === 'ô' ? 2 : 1), 0);
};

const getSmsCount = (messageLength) => {
    if (messageLength <= 160) {
        return 1;
    } else if (messageLength <= 320) {
        return 2;
    }
    return Math.ceil(messageLength / 160);
};

function CreateCampaignForm() {
    const [campaignName, setCampaignName] = useState('');
    const [message, setMessage] = useState('');
    const [listId, setListId] = useState('');
    const [targetAllContacts, setTargetAllContacts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

    const [optionLater, setOptionLater] = useState(false);
    const initialDate = dayjs().add(7, 'day').set('hour', 9).set('minute', 15).set('second', 0);
    const maxDate = dayjs().add(6, 'month');
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const minDate = dayjs().add(24, 'hour');

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };

    useEffect(() => {
        api.get('/api/smsko/contacts/lists/')
            .then(res => setLists(res.data))
            .catch(err => console.error('Error fetching lists', err));
    }, []);

    const handleMessageChange = (event) => {
        const newMessage = event.target.value;
        if (newMessage.split('').every(isGSMCharacter) && getMessageLength(newMessage) <= 320) {
            setMessage(newMessage);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formatDateBefiana = optionLater ? selectedDate.toISOString() : null;
        try {
            const payload = {
                name: campaignName,
                message: message,
                list: targetAllContacts ? null : listId,
                target_all_contacts: targetAllContacts,
                send_at: formatDateBefiana
            };
            await api.post('/api/smsko/sms/campaigns/create/', payload);
            setSnackbar({ open: true, message: 'Campagne créée avec succès!', severity: 'success' });
            setCampaignName('');
            setMessage('');
            setListId('');
            setTargetAllContacts(false);
            setOptionLater(false);
            setSelectedDate(initialDate);
        } catch (error) {
            setSnackbar({ open: true, message: 'Erreur lors de la création de la campagne.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
                fullWidth
                label="Nom de la campagne"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
                sx={{ mb: 2 }}
            />
            <TextField
                fullWidth
                label="Message"
                value={message}
                onChange={handleMessageChange}
                multiline
                rows={4}
                helperText={`${getMessageLength(message)}/320 caractères (${getSmsCount(getMessageLength(message))} SMS)`}
                inputProps={{ maxLength: 320 }}
                sx={{ mb: 2 }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={targetAllContacts}
                        onChange={(e) => setTargetAllContacts(e.target.checked)}
                    />
                }
                label="Envoyer à tous mes contacts"
                sx={{mb:1}}
            />
            {!targetAllContacts && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="list-select-label">Liste de contacts</InputLabel>
                    <Select
                        labelId="list-select-label"
                        id="list-select"
                        value={listId}
                        label="Liste de contacts"
                        onChange={(e) => setListId(e.target.value)}
                    >
                        {lists.map((list) => (
                            <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <br />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={optionLater}
                        onChange={(e) => setOptionLater(e.target.checked)}
                    />
                }
                label="Options de planification"
                sx={{mb:1}}
            />

            {optionLater && (
                <DateTimePicker
                    label="Choisissez la date et l'heure"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={minDate}
                    maxDate={maxDate}
                    inputFormat="DD/MM/YYYY HH:mm"
                />
            )}
            <br />
            <Button sx={{mt:1}} type="submit" variant="contained" color="primary" disabled={loading}>
                Créer la campagne
            </Button>
            {loading && <CircularProgress />}
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
        </LocalizationProvider>
    );
}

export default CreateCampaignForm;