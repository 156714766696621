import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import dayjs from 'dayjs';
import api from '../../../../services/api';  

function SentSMSList() {
    const [smsList, setSmsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchSentSMS();
    }, []);

    const fetchSentSMS = async () => {
        setLoading(true);
        try {
            const response = await api.get('/api/smsko/sms/sent-sms/');
            const formattedData = response.data.map(sms => ({
                ...sms,
                send_date: dayjs(sms.send_date).format('DD/MM/YYYY HH:mm:ss')
            }));
            setSmsList(formattedData);
        } catch (error) {
            console.error('Failed to fetch sent SMS:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'phone_number', headerName: 'Numéro', width: 130 },
        { field: 'message', headerName: 'Message', width: 150, flex: 1 },
        { field: 'send_date', headerName: 'Date d\'envoi', width: 180 },
        { field: 'callback_data', headerName: 'Ref SMS by BEFIANA', width: 250 },
    ];

    const handleSearch = (event) => {
        setSearchText(event.target.value.toLowerCase());
    };

    const filteredSMS = smsList.filter((sms) => {
        return sms.message.toLowerCase().includes(searchText) ||
               sms.phone_number.includes(searchText);
    });

    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <TextField
                label="Rechercher"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={handleSearch}
                sx={{ mb: 2 }}
            />
            {loading ? (
                <CircularProgress />
            ) : (
                <DataGrid
                    rows={filteredSMS}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            )}
        </Box>
    );
}

export default SentSMSList;