import React, { useState, useEffect } from 'react';
import { Paper, Typography, Divider, List, ListItem, Tooltip, ListItemButton, ListItemIcon, ListItemText, AccordionSummary, Accordion, AccordionDetails, Button } from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SettingsIcon from '@mui/icons-material/Settings';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import api from '../../../../services/api';

dayjs.extend(utc);
dayjs.extend(timezone);

const getMadagascarTime = () => {
    return dayjs().tz('Indian/Antananarivo').format('D MMMM YYYY à H:mm');
};

function SysSystem() {
    const [senderName, setSenderName] = useState('BEFIANA');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSenderName = async () => {
            try {
                const response = await api.get('/api/smsko/get-sender-name/');
                setSenderName(response.data.sender_name);
            } catch (error) {
                // console.error('Erreur lors de la récupération du nom de l\'expéditeur:', error);
                setSenderName('SERVICE NON ACTIVE');
            } finally {
                setLoading(false);
            }
        };

        fetchSenderName();
    }, []);

    const madagascarTime = getMadagascarTime();

    return (
        <Paper elevation={1} sx={{ margin: 'auto', mt: 1, p: 2, mb: 3, maxWidth: 899 }}>
            <Button variant="text" color="primary" startIcon={<SettingsIcon />}>
                Configuration du système
            </Button>
            <Divider></Divider>
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <AccessAlarmIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={`Fuseau horaire: Heure de Madagascar (GMT+3) - ${madagascarTime}`} />
                    </ListItemButton>
                </ListItem>
                <Tooltip title="Si vous avez besoin d'aide, appelez ce numéro : 032 63 166 59 / 034 09 721 93" arrow placement='bottom-start'>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <BadgeIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText primary={`Nom de l'expéditeur : ${loading ? 'Chargement...' : senderName}`} />
                        </ListItemButton>
                    </ListItem>
                </Tooltip>
            </List>

            <Accordion elevation={0}>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography>Vous pouvez personnaliser le nom de l'expéditeur avec celui de votre entreprise.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle2" color="primary">Appelez ce numéro 032 63 166 59 / 034 09 721 93 ou envoyez un email à service.client@befiana.com pour demander la personnalisation du nom d'expéditeur de votre SMS au nom de votre entreprise.</Typography> <br />
                    <Typography variant="subtitle2" color="initial">Deux options s'offrent à vous :</Typography>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2" color="initial">
                                    <b>- Achat à vie : Achetez une licence à vie pour votre compte. Cela vous permettra d'envoyer des SMS au nom de votre entreprise avec nos offres prépayées. </b>
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="subtitle2" color="initial">
                                    <b>- Abonnement</b> : Inscrivez-vous à notre offre d'abonnement avec un engagement de 12 mois et recevez gratuitement le nom d'expéditeur personnalisé (Non Disponible).
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}

export default SysSystem;