import React, { useState, useEffect } from 'react';
import {
  Box, Button, CircularProgress, Snackbar, TextField, MenuItem, Select, FormControl, InputLabel, Typography, Badge, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Backdrop
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { Autocomplete } from '@mui/material';
import { Alert } from '@mui/material'; 
import { Send as SendIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import api from '../../../../services/api';
import './VoucherTable.css';

const VoucherTable = () => {
  const [vouchers, setVouchers] = useState([]);
  const [voucherGroups, setVoucherGroups] = useState([]);
  const [filters, setFilters] = useState({ phone_number: '', status: '', voucher_group: '', min_amount: '', validity_before: null, created_after: null });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editFormData, setEditFormData] = useState({ voucher_group: '', name_or_function: '', phone_number: '', amount: '' });
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  useEffect(() => {
    fetchVouchers();
    fetchVoucherGroups();
  }, []);

  const fetchVouchers = async (params = '') => {
    setLoading(true);
    try {
      const response = await api.get(`/api/ibondacha/vouchers/${params}`);
      const vouchersWithDetails = await Promise.all(response.data.map(async (voucher) => {
        const groupResponse = await api.get(`/api/ibondacha/voucher-groups/h/${voucher.voucher_group}/`);
        const storeResponse = voucher.store ? await api.get(`/api/ibondacha/stores/r/${voucher.store}/`) : null;
        // const businessResponse = voucher.store ? await api.get(`/api/ibondacha/stores/r/${voucher.store}/`) : null;
        return {
          ...voucher,
          groupName: groupResponse.data.beneficiary.name,
          storeName: storeResponse ? storeResponse.data.name : ''
        };
      }));
      setVouchers(vouchersWithDetails);
    } catch (error) {
      console.error('Error fetching vouchers:', error);
    }
    setLoading(false);
  };

  const fetchVoucherGroups = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/ibondacha/voucher-groups/');
      setVoucherGroups(response.data);
    } catch (error) {
      console.error('Error fetching voucher groups:', error);
    }
    setLoading(false);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setFilters({ ...filters, [name]: date });
  };

  const applyFilters = () => {
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        if (filters[key] instanceof dayjs) {
          queryParams.append(key, filters[key].toISOString());
        } else {
          queryParams.append(key, filters[key]);
        }
      }
    });
    fetchVouchers(`filter/?${queryParams.toString()}`);
  };

  const handleEditClick = (voucher) => {
    setSelectedVoucher(voucher);
    setEditFormData({
      voucher_group: voucher.voucher_group,
      name_or_function: voucher.name_or_function,
      phone_number: voucher.phone_number,
      amount: voucher.amount
    });
    setOpenEditDialog(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleEditSubmit = async () => {
    setLoading(true);
    try {
      await api.put(`/api/ibondacha/vouchers/${selectedVoucher.id}/`, editFormData);
      setSnackbarMessage('Bon d\'achat mis à jour avec succès.');
      setSnackbarSeverity('success');
      fetchVouchers();
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Erreur lors de la mise à jour du bon d\'achat.';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      console.error('Error updating voucher:', error);
    }
    setSnackbarOpen(true);
    setLoading(false);
    setOpenEditDialog(false);
  };

  const handleDeleteClick = (voucher) => {
    setSelectedVoucher(voucher);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await api.delete(`/api/ibondacha/vouchers/${selectedVoucher.id}/`);
      setSnackbarMessage('Bon d\'achat supprimé avec succès.');
      setSnackbarSeverity('success');
      fetchVouchers();
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Erreur lors de la suppression du bon d\'achat.';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      console.error('Error deleting voucher:', error);
    }
    setSnackbarOpen(true);
    setLoading(false);
    setOpenDeleteDialog(false);
  };

  const sendSMS = async (phoneNumber) => {
    setLoading(true);
    try {
      await api.post('/api/ibondacha/sms/', { phone_number: phoneNumber });
      setLoading(false);
      setSnackbarMessage('Le renvoi du SMS a été envoyé avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch {
      setLoading(false);
    }
  };

  const handleResendClick = (phone_number) => {
    sendSMS(phone_number)
  };

  const formatDate = (date) => dayjs(date).format('HH:mm DD-MM-YYYY');
  const formatShortDate = (date) => dayjs(date).format('DD-MM-YYYY');

  const columns = [
    {
      field: 'resend',
      headerName: 'SMS',
      width: 65,
      renderCell: (params) => (
        <IconButton
          color="ib1"
          onClick={() => handleResendClick(params.row.phone_number)}
        >
          <Badge badgeContent={params.row.notification_count || 0} color="error">
            <SendIcon />
          </Badge>
        </IconButton>
      ),
    },
    // { field: 'id', headerName: 'ID', width: 50 },
    { field: 'groupName', headerName: 'Entreprise', width: 85 },
    { field: 'name_or_function', headerName: 'Nom', width: 100 },
    { field: 'phone_number', headerName: 'Phone', width: 100 },
    { field: 'amount', headerName: 'Montant', width: 90, renderCell: (params) => parseInt(params.value, 10) },
    {
      field: 'status',
      headerName: ' ',
      width: 5,
      renderCell: (params) => {
        const statusMap = {
          1: 'A',
          2: 'B',
          3: 'E',
          0: 'D'
        };
        return statusMap[params.value];
      },
      cellClassName: (params) => {
        const statusColorMap = {
          1: 'status-active',
          2: 'status-blocked',
          3: 'status-error',
          0: 'status-used'
        };
        return statusColorMap[params.value];
      }
    },
    { field: 'storeName', headerName: 'Magasin', width: 130 },
    { field: 'voucher_code', headerName: 'iBondacha', width: 90 },
    // { field: 'validity', headerName: 'Validité', width: 100, renderCell: (params) => formatShortDate(params.value) },
    { field: 'updated_at', headerName: 'Dernière action', width: 140, renderCell: (params) => formatDate(params.value) },
    {
      field: 'edit',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton color="secondary" onClick={() => handleDeleteClick(params.row)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mx: 'auto', width: 1050 }}>
        <Backdrop open={loading} style={{ zIndex: 999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Typography variant="h5" gutterBottom>
          Gestion des Bons d'Achat
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Numéro de Téléphone"
            name="phone_number"
            value={filters.phone_number}
            onChange={handleFilterChange}
            variant="outlined"
          />
          <FormControl variant="outlined" sx={{ minWidth: 160 }}>
            <InputLabel id="status-label">Statut</InputLabel>
            <Select
              labelId="status-label"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              label="Statut"
            >
              <MenuItem value=""><em>Aucun</em></MenuItem>
              <MenuItem value={1}>Actif</MenuItem>
              <MenuItem value={2}>Bloqué ou Perdu</MenuItem>
              <MenuItem value={3}>Erreur de caisse</MenuItem>
              <MenuItem value={0}>Déjà Utilisé</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            options={voucherGroups}
            getOptionLabel={(option) => `${option.name} (${option.beneficiary})`}
            onChange={(event, newValue) => setFilters({ ...filters, voucher_group: newValue ? newValue.id : '' })}
            renderInput={(params) => <TextField {...params} label="Groupe iBondacha" variant="outlined" />}
            sx={{ minWidth: 200 }}
          />
          <TextField
            label="Montant Min"
            name="min_amount"
            value={filters.min_amount}
            onChange={handleFilterChange}
            variant="outlined"
            type="number"
          />
          <Button variant="contained" color="ib2" onClick={applyFilters}>
            Appliquer les Filtres
          </Button>
        </Box>
        <Paper elevation={1} sx={{ p: 3 }}>
          <div style={{ height: 600, width: 1000 }}>
            <DataGrid 
                rows={vouchers} 
                columns={columns} 
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText} 
                getRowClassName={(params) => {
                    const statusColorMap = {
                      1: 'status-active',
                      2: 'status-blocked',
                      3: 'status-error',
                      0: 'status-used'
                    };
                    return statusColorMap[params.row.status];
                }}
            />
          </div>
        </Paper>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
          <DialogTitle>Modifier le Bon d'Achat</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                options={voucherGroups}
                getOptionLabel={(option) => option.name}
                value={voucherGroups.find(group => group.id === editFormData.voucher_group) || null}
                onChange={(event, newValue) => setEditFormData({ ...editFormData, voucher_group: newValue ? newValue.id : '' })}
                renderInput={(params) => <TextField {...params} label="Groupe de Bon d'Achat" variant="outlined" />}
              />
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              name="name_or_function"
              label="Nom ou Fonction"
              value={editFormData.name_or_function}
              onChange={handleEditChange}
            />
            <TextField
              fullWidth
              margin="normal"
              name="phone_number"
              label="Numéro de Téléphone"
              value={editFormData.phone_number}
              onChange={handleEditChange}
              inputProps={{ maxLength: 9 }}
            />
            <TextField
              fullWidth
              margin="normal"
              name="amount"
              label="Montant"
              type="number"
              value={editFormData.amount}
              onChange={handleEditChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditDialog(false)} color="primary">
              Annuler
            </Button>
            <Button onClick={handleEditSubmit} color="primary">
              Mettre à jour
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
          <DialogTitle>Confirmer la Suppression</DialogTitle>
          <DialogContent>
            <Typography>Êtes-vous sûr de vouloir supprimer ce bon d'achat ?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Annuler
            </Button>
            <Button onClick={handleDeleteConfirm} color="secondary">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default VoucherTable;