import React, { useState, useEffect } from 'react';
import { TextField, Dialog, Backdrop, DialogTitle, DialogContent, Box, Button, Snackbar, Alert, Typography, Divider, Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SendIcon from '@mui/icons-material/Send';
import api from '../../../../services/api';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet-async';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import 'dayjs/locale/fr'; 
import localizedFormat from 'dayjs/plugin/localizedFormat';
import ContactsDownloadTemplate from './FileExemple';

dayjs.extend(localizedFormat);
dayjs.locale('fr');

const isGSMCharacter = (char) => {
    const GSM_03_38_REGEX = /^[A-Za-z0-9 \r\n@£$¥èéêùìòôÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!"#¤%&'()*+,-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\[~\]|€]*$/;
    return GSM_03_38_REGEX.test(char);
};

const getMessageLength = (message) => {
    return message.split('').reduce((count, char) => count + (char === 'ê' || char === 'ô' ? 2 : 1), 0);
};

const getSmsCount = (messageLength) => {
    if (messageLength <= 160) {
        return 1;
    } else if (messageLength <= 320) {
        return 2;
    }
    return Math.ceil(messageLength / 160);
};

function MessageForm({ sendTo = null }) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingLater, setLoadingLater] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [openDetails, setOpenDetails] = useState(false);

    const initialDate = dayjs().add(7, 'day').set('hour', 9).set('minute', 15).set('second', 0);
    const maxDate = dayjs().add(6, 'month');
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const minDate = dayjs().add(24, 'hour');

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };

    const handleSendLater = async () => {
        const formatDateBefiana = selectedDate.toISOString();

        if (phoneNumber.length === 10 && getMessageLength(message) <= 320) {
            setLoadingLater(true);

            const cleanedPhoneNumber = phoneNumber.replace(/^0+/, '');
            try {
                await api.post('/api/smsko/send-sms-later/', {
                    phone_number: cleanedPhoneNumber,
                    message: message,
                    send_at: formatDateBefiana
                });
                setSnackbarMessage('Le message a été programmé avec succès !');
                setSnackbarSeverity('success');
                setOpenDetails(false);
                setMessage('');
                setPhoneNumber('');
            } catch (error) {
                const errorMessage = error.response?.data?.error || 'Erreur lors de la programmation du message.';
                setSnackbarMessage(errorMessage);
                setSnackbarSeverity('error');
                setLoadingLater(false);
            } finally {
                setSnackbarOpen(true);
                setLoadingLater(false);
            }
        }
    }

    useEffect(() => {
        if (sendTo) {
            setPhoneNumber(sendTo);
        }
    }, [sendTo])

    const handlePhoneChange = (event) => {
        if (event.target.value.length <= 10) {
            setPhoneNumber(event.target.value);
        }
    };

    const handleMessageChange = (event) => {
        const { value } = event.target;
        if (value.split('').every(isGSMCharacter) && getMessageLength(value) <= 320) {
            setMessage(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (phoneNumber.length === 10 && getMessageLength(message) <= 320) {
            setLoading(true);
            const cleanedPhoneNumber = phoneNumber.replace(/^0+/, '');
            try {
                await api.post('/api/smsko/send-sms/', {
                    phone_number: cleanedPhoneNumber,
                    message
                });
                setSnackbarMessage('Message envoyé avec succès !');
                setSnackbarSeverity('success');
                setMessage('');
                setPhoneNumber('');
            } catch (error) {
                const errorMessage = error.response?.data?.error || 'Erreur lors de l\'envoi du message.';
                setSnackbarMessage(errorMessage);
                setSnackbarSeverity('error');
            } finally {
                setLoading(false);
                setSnackbarOpen(true);
            }
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
                fullWidth
                label="Numéro de téléphone"
                value={phoneNumber}
                onChange={handlePhoneChange}
                helperText="Le numéro de téléphone doit contenir 10 chiffres."
                sx={{ mb: 2 }}
                inputProps={{ maxLength: 10 }}
            />
            <TextField
                fullWidth
                label="Message"
                value={message}
                onChange={handleMessageChange}
                helperText={`${getMessageLength(message)}/320 caractères (${getSmsCount(getMessageLength(message))} SMS)`}
                multiline
                rows={4}
                sx={{ mb: 2 }}
            />

            <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
                loadingIndicator={<CircularProgress size={24} color="inherit" />} // Indicateur personnalisé
                disabled={phoneNumber.length !== 10 || getMessageLength(message) > 320 || getMessageLength(message) < 1}
                sx={{ mr: 2 }}
            >
                Envoyer
            </LoadingButton>

            <Button variant="outlined" color="secondary" onClick={() => setOpenDetails(true)} disabled={phoneNumber.length !== 10 || getMessageLength(message) > 320 || getMessageLength(message) < 1}>
                Programmer
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Dialog 
                open={openDetails} 
                onClose={() => setOpenDetails(false)} 
                fullWidth 
                fullScreen
            >
                <DialogTitle color="primary"> <Button variant="text" color="primary" size='large' startIcon={<DateRangeIcon />}>
                    Programmer un message 
                    <Helmet>
                        <title>Programmer un message - BEFIANA Connect</title>
                    </Helmet>
                </Button> </DialogTitle>
                <DialogContent>
                <Box sx={{ mt: 2 }}>
                    <DateTimePicker
                        label="Choisissez la date et l'heure"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={minDate}
                        maxDate={maxDate}
                        inputFormat="DD/MM/YYYY HH:mm"
                    />

                    <Typography variant="subtitle2" color="initial" m={2}><b>Tel. : </b>{phoneNumber}</Typography>
                    <Typography variant="subtitle2" color="initial"  m={2}><b>Message : </b> {message}</Typography>
                    <Button onClick={()=> handleSendLater()} variant="contained" color="primary" sx={{mr:1}} startIcon={<SendIcon />} disableElevation>
                      Programmer maintenant
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => setOpenDetails(false)}>
                      Annuler
                    </Button>
                    <Divider sx={{mt:3, mb:6}}></Divider>

                    <Paper elevation={1} sx={{margin: '0 auto', backgroundColor:'#f1fff5'}}>
                        <ContactsDownloadTemplate />
                    </Paper>

                    <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
                    open={loadingLater}
                    >
                    <CircularProgress color="inherit" />
                    </Backdrop>

                </Box>
                </DialogContent>
                
            </Dialog>
        </Box>
        </LocalizationProvider>
    );
}

export default MessageForm;