import React, { useState, useEffect } from 'react';
import { Switch, FormControlLabel, Snackbar, Alert, Divider, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import api from '../../../../services/api';
import SMSHappyBirthday from '../../../../components/lotties/HappyBirthday';

function BirthdaySMSToggle() {
    const [birthdaySMS, setBirthdaySMS] = useState(false);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [companyName, setCompanyName] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const maxCompanyNameLength = 19;

    useEffect(() => {
        const fetchBirthdaySMSStatus = async () => {
            try {
                const response = await api.get('/api/smsko/toggle-birthday-sms/status/');
                setBirthdaySMS(response.data.birthday_sms_enabled);
            } catch (error) {
                console.error('Erreur lors de la récupération du statut Birthday SMS.');
            } finally {
                setLoading(false);
            }
        };

        const fetchCompanyName = async () => {
            try {
                const response = await api.get('/api/smsko/company-name/');
                setCompanyName(response.data.company_name || '');
            } catch (error) {
                console.error('Erreur lors de la récupération du nom de l\'entreprise:', error);
            }
        };

        fetchBirthdaySMSStatus();
        fetchCompanyName();
    }, []);

    const handleToggle = async () => {
        try {
            const response = await api.post('/api/smsko/toggle-birthday-sms/');
            setBirthdaySMS(response.data.birthday_sms_enabled);
            setSnackbarMessage('Le paramètre SMS d\'anniversaire a été mis à jour avec succès');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Échec de la mise à jour des paramètres SMS d\'anniversaire');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCompanyNameChange = (event) => {
        const value = event.target.value;
        if (value.length <= maxCompanyNameLength) {
            setNewCompanyName(value);
        }
    };

    const handleCompanyNameSave = async () => {
        try {
            const response = await api.post('/api/smsko/update-company-name/', { company_name: newCompanyName });
            setCompanyName(response.data.company_name);
            setSnackbarMessage('Nom de l\'entreprise mis à jour avec succès');
            setSnackbarSeverity('success');
            setDialogOpen(false);
        } catch (error) {
            setSnackbarMessage('Échec de la mise à jour du nom de l\'entreprise');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <SMSHappyBirthday />
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="subtitle2" color="initial">
                        Faites plaisir à vos clients ou membres de votre établissement pour leur anniversaire 🎉🥳 ! Activez l'option SMS d'anniversaire pour envoyer automatiquement un message chaleureux à vos contacts le jour de leur anniversaire. Assurez-vous simplement que la date de naissance de votre contact est indiquée dans son profil.
                    </Typography>
                </Grid>
            </Grid>

            <br />
            <Button variant="text" color="secondary" onClick={handleDialogOpen}>
                {companyName ? companyName : "Entrer Nom de l'entreprise"}
            </Button>
            <FormControlLabel
                control={
                    <Switch
                        checked={birthdaySMS}
                        onChange={handleToggle}
                        name="birthdaySMS"
                        color="primary"
                        disabled={loading}
                    />
                }
                label="Activer les SMS d'anniversaire"
            />
            <br /> <br />
            <Divider />
            <Typography variant="subtitle2" color="primary">
                Toute l'équipe de <b><span style={{ color: '#FF8C00' }}> {companyName ? companyName : '[NOM DE L\'ENTREPRISE]'} </span> </b> vous souhaite un très joyeux anniversaire ! Profitez de cette journée spéciale et que tous vos rêves se réalisent.
            </Typography>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>
                    <Button variant="text" color="primary" startIcon={<BusinessIcon />}>
                        Entrer Nom de l'entreprise
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuillez entrer le nom de votre entreprise pour personnaliser les SMS.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nom de l'entreprise"
                        type="text"
                        fullWidth
                        value={newCompanyName}
                        onChange={handleCompanyNameChange}
                        inputProps={{ maxLength: maxCompanyNameLength }}
                        helperText={`${newCompanyName.length}/${maxCompanyNameLength} caractères`}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleCompanyNameSave} color="primary">
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default BirthdaySMSToggle;