import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Snackbar, Alert, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import api from '../../../../services/api';
import MyAccountCoordAdd from '../../../../components/accounts/AboutAdressAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Helmet } from 'react-helmet-async';

function BuySMS({ onPurchaseSuccess }) {
    const [quantity, setQuantity] = useState(1);
    const [billingAddresses, setBillingAddresses] = useState([]);
    const [selectedBillingId, setSelectedBillingId] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const smsPrice = 65;

    useEffect(() => {
        fetchBillingAddresses();
    }, []);

    const fetchBillingAddresses = async () => {
        try {
            const response = await api.get('/api/addresses/');
            setBillingAddresses(response.data);
        } catch (error) {
            console.error('Failed to fetch billing addresses', error);
        }
    };

    const handleRefreshAddresses = () => {
        fetchBillingAddresses();
    };

    const handlePurchase = async () => {
        try {
            const response = await api.post('/api/smsko/sms/purchase/', {
                quantity,
                total_price: quantity * smsPrice,
                rate: 1,
                billing_id: selectedBillingId
            });
            if (response.status === 201) {
                setSnackbarMessage('Votre achat est en cours de paiement et vérification.');
                setSnackbarOpen(true);
                setQuantity(1);
                setSelectedBillingId('');

                onPurchaseSuccess();
            }
        } catch (error) {
            console.error('Error during purchase', error);
            setSnackbarMessage('Échec de l\'achat.');
            setSnackbarOpen(true);
        }
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        handleRefreshAddresses();
    };

    return (
        <Box sx={{ p: 2 }}>
            <TextField
                type="number"
                label="Nombre de SMS"
                value={quantity}
                onChange={e => setQuantity(Math.min(111000, Math.max(1, parseInt(e.target.value, 10))))}
                sx={{ mb: 2 }}
            />
            <Typography sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <ShoppingCartIcon sx={{ mr: 1 }} />
                Total: {(quantity * smsPrice).toLocaleString('fr-MG', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} MGA
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Adresse de Facturation</InputLabel>
                <Select
                    value={selectedBillingId}
                    label="Adresse de Facturation"
                    onChange={e => setSelectedBillingId(e.target.value)}
                    required
                >
                    {billingAddresses.map((address) => (
                        <MenuItem key={address.id} value={address.id}>
                            {address.company_name + " " + address.country + " " + address.address_line_1}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button onClick={handlePurchase} variant="contained" color="primary" disabled={billingAddresses.length === 0 || selectedBillingId === ''} >
                Acheter
            </Button>
            <IconButton onClick={handleRefreshAddresses} color="primary" sx={{ ml: 2 }}>
                <RefreshIcon />
            </IconButton>
            <Button onClick={handleOpenDialog} variant="outlined" sx={{ ml: 1 }}>
                Ajouter une adresse
            </Button>
            <Snackbar open={snackbarOpen} autoHideDuration={8000} onClose={() => setSnackbarOpen(false)}>
                <Alert severity="info">{snackbarMessage}</Alert>
            </Snackbar>

            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogContent>
                    <Helmet>
                        <title>Ajouter une adresse - BEFIANA Connect</title>
                    </Helmet>
                    <MyAccountCoordAdd />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align='center'>
                        Vous avez la possibilité de sauvegarder diverses adresses en fonction de vos besoins sur Befiana Connect, telles que votre adresse de domicile, de facturation ou de travail. Cette fonctionnalité nous permet de mieux personnaliser et d'optimiser nos services pour répondre précisément à vos exigences.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default BuySMS;