import React from 'react';
import { Box, Typography } from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import ContactPhone from '@mui/icons-material/ContactPhone';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import GppBadIcon from '@mui/icons-material/GppBad';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';


function WidgetSMS({nb, name, color, colorT, icon}) {
  const Icon = {
    'Sms': SmsIcon,
    'SendAndArchive': SendAndArchiveIcon,
    'ContactPhone': ContactPhone,
    'ShoppingCart': ShoppingCartIcon,
    'GppBad': GppBadIcon,
    'PhonelinkRing': PhonelinkRingIcon,
    'PlaylistAddCheckCircle': PlaylistAddCheckCircleIcon
  }[icon] || SmsIcon;

  return (
    <Box sx={{
      bgcolor: color,
      width: 175, 
      height: 175,
      borderRadius: '12px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: 3 
    }}>
      <Icon sx={{ fontSize: 40, color: colorT }} />
      <Typography variant="h4" component="div" sx={{ color: colorT, fontWeight: 'bold' }}>
        {nb}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: colorT }}>
        {name}
      </Typography>
    </Box>
  );
}

export default WidgetSMS;