import React, { useEffect, useState } from 'react';
import { Container, CircularProgress, Snackbar, Alert } from '@mui/material';
import api from '../../../../services/api';

const SMSActivationStatus = () => {
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await api.get('/api/smsko/sms-activation-status/');
                setStatus(response.data.status);
            } catch (err) {
                setError('Erreur lors de la vérification du statut de l\'activation du service SMS.');
            } finally {
                setLoading(false);
            }
        };

        fetchStatus();
    }, []);

    return (
        <Container maxWidth="md" sx={{mt: 3}}>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Snackbar open autoHideDuration={6000}>
                    <Alert severity="error">{error}</Alert>
                </Snackbar>
            ) : (
                <div>
                    {status !== 1 ? (
                        <Alert severity="warning">
                            {status === "No activation request found" ? (
                                <>Votre service SMS n'est pas encore activé. Veuillez compléter votre demande d'activation pour utiliser ce service dans les paramètres SMS si ce n'est pas encore fait. Contactez le service support au 032 63 166 59 / 034 09 721 93 pour plus d'informations</>
                            ) : (
                                <>Votre service SMS n'est pas encore activé. Veuillez compléter votre demande d'activation pour utiliser ce service dans les paramètres SMS si ce n'est pas encore fait. Contactez le service support au 032 63 166 59 / 034 09 721 93 pour plus d'informations</>
                            )}
                        </Alert>
                    ) : (
                        <Alert severity="success">
                            Votre service SMS by BEFIANA est activé. Vous pouvez désormais envoyer des SMS.
                        </Alert>
                    )}
                    <br />
                </div>
            )}
        </Container>
    );
};

export default SMSActivationStatus;
