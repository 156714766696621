import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Typography, Paper, Alert, Container, TextField } from '@mui/material';
import { styled } from '@mui/system';
import api from '../../../../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  textAlign: 'center',
}));

const VoucherDifference = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchVoucherDifference = async () => {
    try {
      const response = await api.get(`/api/ibondacha/voucher-difference/${phoneNumber}/`);
      setData(response.data);
      setError(null);
    } catch (err) {
      setError(err.response.data.error);
      setData(null);
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetchVoucherDifference();
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
        <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Numéro de téléphone"
            name="phoneNumber"
            autoComplete="phone"
            autoFocus
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <Button
            type="submit"
            variant="contained"
            color="ib2"
            fullWidth
          >
            Calculer la différence des vouchers
          </Button>
        </form>

        {data && (
          <StyledPaper elevation={3}>
            <Typography variant="h6">Résultats</Typography>
            <Typography variant="body1">Numéro de téléphone: {"0"+data.phone_number}</Typography>
            <Typography variant="body1">Montant du dernier voucher: {parseInt(data.last_voucher_amount)} Ar</Typography>
            <Typography variant="body1">Montant du second dernier voucher: {parseInt(data.second_last_voucher_amount)} Ar</Typography>
            <Typography variant="h5">Différence: {parseInt(data.difference)} Ar</Typography>
          </StyledPaper>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </Box>
    </Container>
  );
};

export default VoucherDifference;